.btn-ims-index {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    margin-left: 20px;
}

.btn-ims-index:hover, .btn-ims-index[indexed="1"] {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
}

/* button img */
.btn-ims-index:hover>img {
    width:28;
    height:28;
}

/* button title */
.btn-ims-index>div {
    font-size: large;
}