/* .outline */
.inv-outline {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.inv-outline-header {
    flex: none;
    background-color: white;
    padding: 8px 5px;
    margin-bottom: 8px;
}

.inv-outline-main {
    flex: auto;
    overflow: hidden;
}