@import 'antd/dist/antd.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.infobox-actions {
  height: 30px !important;
}

.ant-input-group.ant-input-group-compact > *:first-child, .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector, .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input, .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.ant-input-group.ant-input-group-compact  *:last-child, .ant-input-group.ant-input-group-compact  .ant-select:last-child  .ant-select-selector, .ant-input-group.ant-input-group-compact  .ant-cascader-picker:last-child .ant-input, .ant-input-group.ant-input-group-compact  .ant-cascader-picker-focused:last-child .ant-input {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}