/* .inv-setting */
.inv-setting {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.inv-setting-header {
    flex: none;
    background-color: white;
    padding: 8px 5px;
    margin-bottom: 8px;
}
.inv-setting-header span {
    margin-right: 10px;
}

.inv-setting-main {
    flex: auto;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: scroll;
}

.ant-tabs-content.ant-tabs-content-top {
    height: 100%;
}

.ant-spin-nested-loading {
    height: 100%;
}
.ant-spin-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ant-table {
    flex: 1 1 auto;
    overflow: hidden;
}

.ant-pagination {
    flex: none
}

.ant-table-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ant-table-header {
    flex: none;
}

.ant-table-body {
    flex: auto;
}