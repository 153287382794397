/* .data-analysis */
.data-analysis, .configuration {
    display: flex;
    width: 100%;
}
/* .data-nav */
.data-nav {
    width: 185px;
    flex: none;
}
.data-nav ul {
    list-style: none;
    padding: 10px;
}
.data-nav ul li {
    width: 160px;
    padding: 12px 15px;
    border-radius: 10px;
    color: #333;
    margin-bottom: 10px;
}
.data-nav ul li:hover {
    background-color: #82b2c7;
    color: white;
}
.data-nav ul li.active {
    background-color: #82b2c7;
    color: white;
}

/* .data-content */
.data-content {
    flex: auto;
    overflow: hidden;
} 

/* .runtime-parameter */
.runtime-parameter-header {
    background-color: white;
    padding: 8px 5px;
    margin-bottom: 8px;
}
.runtime-parameter-header span {
    margin-right: 10px;
}
.runtime-parameter-main .side {
    width: 140px;
    height: 260px;
    background-color: white;
    border: 1px solid #ccc;
    margin-bottom: 8px;
}
.runtime-parameter-main .side-left {
    float: left;
    margin-right: 6px;
}
.runtime-parameter-main .side-right {
    float: right;
    margin-left: 6px;
}
.runtime-parameter-main .side .title {
    background-color: ​#f2f7f8;
    font-weight: 700;
    font-size: 13px;
    padding: 3px;
}
.runtime-parameter-main .side .item ul {
    list-style: none;
    padding: 0;
}
.runtime-parameter-main .side .item ul li {
    font-size: 13px;
    padding: 4px;
}
.runtime-parameter-main .side .item ul li:hover {
    background-color: #ddd;
}
.runtime-parameter-main .side .item ul li.active {
    background-color: #bedef0;
}
.runtime-parameter-main .box {
    display: inline-block;
    width: calc(50% - 149px);
    height: 260px;
    background-color: white;
}
.runtime-parameter-main .box-left {
    margin-right: 6px;
}
.runtime-parameter-main .box .title {
    font-size: 17px;
    font-weight: 400;
    text-align: center;
}
.runtime-parameter-main .box .chart {
    margin-top: -20px;
}

/* .energy-statistics */
.energy-statistics-header {
    background-color: white;
    padding: 8px 5px;
    margin-bottom: 8px;
}
.energy-statistics-header span {
    margin-right: 10px;
}
.energy-statistics-main .side {
    width: 176px;
    height: 496px;
    background-color: white;
    border: 1px solid #ccc;
    margin-right: 6px;
    float: left;
}
.energy-statistics-main .side .title {
    background-color: ​#f2f7f8;
    font-weight: 700;
    font-size: 13px;
    padding: 3px;
}
.energy-statistics-main .side .item ul {
    list-style: none;
    padding: 0;
}
.energy-statistics-main .side .item ul li {
    font-size: 13px;
    padding: 4px;
}
.energy-statistics-main .side .item ul li:hover {
    background-color: #ddd;
}
.energy-statistics-main .side .item ul li.active {
    background-color: ​#def8d5;
}
.energy-statistics-main .box {
    display: inline-block;
    width: calc(100% - 184px);
    height: 496px;
    background-color: white;
}
.energy-statistics-main .box .title {
    font-size: 17px;
    font-weight: 400;
    text-align: center;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    white-space: nowrap;
    overflow: hidden;
};
